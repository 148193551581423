@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');
html {
  height: 100%;
  font-family: 'Open Sans', sans-serif !important;
}
input:-internal-autofill-selected{
  background-color: #fff !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.MuiInput-underline{
  &:before{
    border-bottom: 1px solid #AA9CE9 !important;
  }
}
.MuiPaper-root{
  color: #5E5B70 !important;
}
.MuiSelect-select{
  &:focus {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}
.MuiAlert-filledWarning,
.MuiAlert-filledSuccess,
.MuiAlert-filledError,
.MuiAlert-filledInfo{
  color:#fff !important
}
.pageBg{
  .makeStyles-navLink-12{
    color:#5E5B70;
  }
}
.MuiTable-root{
  box-shadow:0 1px 30px rgba(108, 89, 180, .08),
}
.MuiTypography-body1{
  font-size: 14px !important;
  
}
.MuiLink-underlineHover{
  &:hover{
    text-decoration: none !important;
  }
}
.MuiCheckbox-colorSecondary.Mui-checked {
  color: #2787C2 !important;
}
.bgSeparation{
  background-color: #F7F5FF;
  border-radius: 4px;
  padding-left: 5px !important;
  margin-bottom: 20px !important;
  .MuiInputLabel-formControl{
    transform: translate(5px, 24px) scale(1) !important;
  }
  .MuiInputLabel-shrink{
    transform: translate(5px, 6px) scale(0.75) !important;
  }

}
._2iA8p44d0WZ-WqRBGcAuEV{
  border:0 !important;
  margin-bottom: 20px;
  position: relative;
  font-family: 'Open Sans', sans-serif !important;
  background-color: #F7F5FF;
  .searchBox{
    width:100%;
    font-family: 'Open Sans', sans-serif !important;
    color:rgba(0, 0, 0, 0.54),
  }
  &::after{
    content: "";
    background-image: url(/static/images/dd-arrow.svg);
    width: 13px;
    height: 6px;
    position: absolute;
    right:10px;
    top:15px;
  }
}
._7ahQImyV4dj0EpcNOjnwA{
  background: #AA9CE9 !important;
  border-radius: 5px !important;
}
.MuiBackdrop-root{
  background-color:rgba(0,0,0, .8) !important;
}
.MuiInput-underline{
  &::before{
    border-bottom: 1px solid #AA9CE9 !important;
  }
}

input[type="text"]{
  &:disabled {
    background: transparent;
  }
}
.ps__thumb-y{
  width:4px !important;
  opacity: 0.6 !important;
}
.ps .ps__rail-x:hover, .ps .ps__rail-y:hover, 
.ps .ps__rail-x:focus, .ps .ps__rail-y:focus, 
.ps .ps__rail-x.ps--clicking, 
.ps .ps__rail-y.ps--clicking{
  background-color: transparent !important;
}
.ps__rail-y:hover > .ps__thumb-y, 
.ps__rail-y:focus > .ps__thumb-y, 
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #fff !important;
  width: 4px !important;
}
.ps__rail-y{
  opacity: 0.6 !important;
}
.MuiInputLabel-formControl{
  z-index: 1;
}


/*upload*/
.ts.form {
  position: relative;
  .field {
    width: 100%;
    clear: both;
    margin: 0 0 1.6em;
    position: relative;
    input:not([type="radio"]), .ts.form .field textarea, .ts.form .field select{
      width: 100%;
      min-width: 1px;
    }
    input{
      line-height: 1.2142em;
      border-bottom: 1px solid #A2A0B0;
      padding: .78571em 1em;
      outline: none;
      opacity: 0;
    z-index: 1;
    position: relative;
    }
}
}
.ts.buttons {
  display: inline-flex;
  justify-content: flex-end;
  width: 100%;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
input[type=number] {
  -moz-appearance:textfield !important;
}
.MuiListItem-root .MuiTouchRipple-child {
  background-color: #6C59B4;
}
.MuiFilledInput-underline{
  &::before{
    border-bottom: 1px solid #AA9CE9 !important;
  }
}
.MuiTableCell-root{
  padding: 20px 16px !important;
}
/*Multiselect override*/
._2iA8p44d0WZ-WqRBGcAuEV{
  display: flex;
  flex-wrap: wrap;
}
._3vt7_Mh4hRCFbp__dFqBCI{
  input{
    order:-1;
    margin-top: 0;
    margin-bottom: 5px;
  }
}
/*Timer*/
#countdown {
  padding: 10px;
  background-color: #253551;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  margin: auto;
  div{
    &:last-of-type{
      .box{
        &::after{
          display: none;
        }
      }
    }
  }
}
#countdown .box {
  padding: 10px 26px;
  position: relative;
  text-align: center;
  &::after{
    content: "";
    position: absolute;
    top:calc(50% - 10px);
    right: 0;
    height: 20px;
    width: 1px;
    border-right: solid 1px #1A1A1A;
  }
  
}
#countdown .col-4:last-child .box {
  border-right-color: transparent;
}
#countdown .box p {
  font-size: 32px;
  font-weight: 600;
  color: #FCA22A;
  margin: 0;
}
#countdown .box .text {
  font-size: 12px;
  color: #FCA22A;
  text-transform: uppercase;
}
.d-content-wrapper {
  display: block !important;
  width: 100%;
}

@media (max-width: 1260px) {
  .makeStyles-typoWrapper-38 {
    h1 {
      font-size: 1rem !important;
    }
  }
}

@media (min-width: 768px) {
  .container {
    width: 1100px;
  }
  h1 {
    font-size: 58px;
  }
  #countdown {
    padding: 4px 48px;
    width:395px;
    margin-bottom: 20px;
  }
}

#bestPagination .MuiTablePagination-toolbar.MuiToolbar-gutters{
  padding-left: 0;
}
#bestPagination .MuiTablePagination-selectRoot{
  margin-left: 0;
  margin-right: 2px;
}
#bestPagination .MuiTablePagination-actions{
  margin-left: 0;
}

#rankPagination .MuiTablePagination-toolbar.MuiToolbar-gutters{
  padding-left: 0;
}
#rankPagination .MuiTablePagination-selectRoot{
  margin-left: 0;
  margin-right: 2px;
}
#rankPagination .MuiTablePagination-actions{
  margin-left: 0;
}
.mt-sm {
  .MuiFormControl-marginNormal {
    margin-top: 2px;
    }
}
